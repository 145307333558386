export default [
  {
    title: '仪表盘',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    header: '联赛',
    icon: 'GridIcon',
    children: [
      {
        title: '所有联赛',
        route: 'series',
        icon: 'LayersIcon',
      },
      {
        title: '联赛详情',
        route: { name: 'series-details', params: { sid: 0 } },
        disabled: true,
        icon: 'LayoutIcon',
      },
      // {
      //   title: '联赛介绍',
      //   route: 'series-intro',
      //   icon: 'BookIcon',
      // },
    ],
  },
  {
    header: '赛事',
    icon: 'CodesandboxIcon',
    children: [
      {
        title: '所有比赛',
        route: 'races',
        icon: 'BoxIcon',
      },
      {
        title: '比赛详情',
        route: { name: 'race', params: { rid: 0 } },
        disabled: true,
        icon: 'PackageIcon',
      },
    ],
  },
  {
    header: '车手',
    icon: 'UserIcon',
    children: [
      {
        title: '所有车手',
        route: 'drivers',
        icon: 'UsersIcon',
      },
      {
        title: '车手详情',
        route: { name: 'driver', params: { did: 0 } },
        disabled: true,
        icon: 'UserCheckIcon',
      },
      {
        title: '赛照查询',
        route: 'driver-license',
        icon: 'InfoIcon',
      },
      // {
      //   title: '联赛介绍',
      //   route: 'series-intro',
      //   icon: 'BookIcon',
      // },
    ],
  },
  {
    header: '安全',
    icon: 'FlagIcon',
    children: [
      {
        title: '仲裁历史',
        route: 'demerits',
        icon: 'FeatherIcon',
      },
    ],
  },
  {
    header: '赛道',
    icon: 'MapPinIcon',
    children: [
      {
        title: '所有赛道',
        route: 'tracks',
        icon: 'MapIcon',
      },
      {
        title: '赛道详情',
        route: { name: 'track', params: { tid: 0 } },
        disabled: true,
        icon: 'NavigationIcon',
      },
    ],
  },
  {
    header: '关于',
    icon: 'HelpCircleIcon',
    children: [
      {
        title: '赛照与等级说明',
        route: 'algo',
        icon: 'StarIcon',
      },
      {
        title: '更新历史',
        route: 'history',
        icon: 'CoffeeIcon',
      },
      {
        title: '关于',
        route: 'about',
        icon: 'FeatherIcon',
      },
    ],
  },
]
